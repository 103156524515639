export const ONBOARDING_USER_ROLES = ["guest", "kyc"];
export const CUSTODY_ONLY_ROLES = ["guest", "kyc", "custody"];
export const INVEST_ONLY_ROLES = ["guest", "kyc", "invest"];

export const TRADE_DASHBOARD_ACCESS_ROLES = ["trader", "agent"]; // roles that will redirect users to trade dashboard after login

export const UK_RISK_WARNING_LINK = "https://archax.com/uk-risk-warning";
export const CRYPTO_KNOWLEDGE_BASE_LINK =
  "https://landing.archax.com/crypto-knowledge-base";
export const ARCHAX_HOMEPAGE = "https://archax.com";

export const localStorageKeys = {
  setupMFAJWT: "ace-web-setup-mfa-jwt",
  token: "ace-web-token",
  acceptedAnalytics: "ace-web-accepted-analytics",
  lastTradingPairSelected: "ace-web-last-trading-pair-selected",
  clientCategorisationAnswers: "client-categorisation-answers",
  restrictedInvestorAnswers: "restricted-investor-answers",
  amlKycFormData: "ace-web-aml-kyc-form-data",
  countryRestrictions: "country-restrictions",
  tradeOrderType: "trade-order-type",
};

// These are semi-ordered here and documented for business context.
// Automated means the backend triggers the change.
// Manual means archax or ops.
export enum KYC_STATUS {
  PERSONAL_PERSONAL_DETAILS = "pPersonalDetails", // AUTOMATED: standard aml kyc flow
  PERSONAL_DOCUMENT_UPLOAD = "pDocumentUpload", // AUTOMATED: standard aml kyc flow
  PERSONAL_EMPLOYMENT_INFO = "pEmploymentInfo", // AUTOMATED: standard aml kyc flow
  PERSONAL_FURTHER_INFO = "pFurtherInfo", // AUTOMATED: standard aml kyc flow
  PERSONAL_QUANTITATIVE_CLASS1 = "pQuantitativeClass1", // DEPRECATED: look to remove in single PR with ace-auth (amend any references in documentation).
  PERSONAL_QUANTITATIVE_CLASS2 = "pQuantitativeClass2", // ?
  PERSONAL_QUALITATIVE_CLASS1 = "pQualitativeClass1", // ?
  CORPORATE_QUALITATIVE_CLASS1 = "cQualitativeClass1", // DEPRECATED: look to remove in single PR with ace-auth (amend any references in documentation)..
  GENERAL_PENDING = "gPending", // AUTOMATED: standard post aml/kyc /finalise call - waiting for ops/compliance for manual review
  GENERAL_COMPLETED = "gCompleted", // MANUAL: set by ops once user is deemed ok.
  GENERAL_FAILED_SAR = "gFailedSAR", // MANUAL: originally intended to be some distinct status in Northrow fail vs Archax Ops deciding the fail, but seems to just be the "general" failure status now.
  GENERAL_FAILED_TECHNICAL = "gFailedTechnical", // MANUAL: set by ops, usually if there was an issue looking at submitted info, but no conclusion drawn. (i don't think this is used)
  GENERAL_UNQUALIFIED = "gUnqualified", // DEPRECATED: - do not use, look to remove in single PR with ace-auth.
  GENERAL_FURTHER_DETAILS = "gFurtherDetails", // MANUAL: set by ops if something is up/risky with user and they need more info
}

export const RISK_WARNING_MODAL = "riskWarningModal";
export const COOLING_OFF_PERIOD_ENDED_MODAL = "coolingOffPeriodEndedModal";
export const KYC_AML_VERIFIED_MODAL = "passedAMLKYCModal";
export const COUNTRY_RESTRICTION_PAGE = "countryRestrictionPage";
export const EMAIL_VERIFICATION_PAGE = "emailVerificationPage";
